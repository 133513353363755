import {
  facebookIcon,
  discordIcon,
  gmailIcon,
  githubIcon,
  instagramIcon,
  linkedinIcon,
  mediumIcon,
  redditIcon,
  telegramIcon,
  teletypeIcon,
  threadsIcon,
  tikTokIcon,
  VKlIcon,
  weiboIcon,
  xIcon,
  youTubeIcon,
} from '@/pages/entity/models/social-base-64-icons'

import {
  advcashIcon,
  alchemyPayIcon,
  BanxaIcon,
  BTCDirectIcon,
  cashAppIcon,
  coinifyIcon,
  coinTRIcon,
  eurlitaIcon,
  FaTPay1Icon,
  geoPayLogoIcon,
  guardarian1Icon,
  instMoneyIcon,
  itezIcon,
  latamexIcon,
  legendTradingIcon,
  mercuryoIcon,
  moonpayLogosIcon,
  neoFiIcon,
  netellerIcon,
  onmeta1Icon,
  onrampIcon,
  paybisIcon,
  payPalIcon,
  paypayBankIcon,
  plaidIcon,
  rampIcon,
  simplexIcon,
  skrillIcon,
  st1Icon,
  stitchMoneyIcon,
  transakIcon,
  treasureIcon,
  uIcon,
  UNLIMINTEULTDIcon,
  visaIcon,
  wertIcon,
  zenIcon,
} from '@/pages/entity/models/payment-systems-base-64-icons'

export const paymentIconsList = [
  { name: 'advcashIcon', key: advcashIcon },
  { name: 'alchemyPayIcon', key: alchemyPayIcon },
  { name: 'BanxaIcon', key: BanxaIcon },
  { name: 'BTCDirectIcon', key: BTCDirectIcon },
  { name: 'cashAppIcon', key: cashAppIcon },
  { name: 'coinifyIcon', key: coinifyIcon },
  { name: 'coinTRIcon', key: coinTRIcon },
  { name: 'eurlitaIcon', key: eurlitaIcon },
  { name: 'FaTPay1Icon', key: FaTPay1Icon },
  { name: 'geoPayLogoIcon', key: geoPayLogoIcon },
  { name: 'guardarian1Icon', key: guardarian1Icon },
  { name: 'instMoneyIcon', key: instMoneyIcon },
  { name: 'itezIcon', key: itezIcon },
  { name: 'latamexIcon', key: latamexIcon },
  { name: 'legendTradingIcon', key: legendTradingIcon },
  { name: 'mercuryoIcon', key: mercuryoIcon },
  { name: 'moonpayLogosIcon', key: moonpayLogosIcon },
  { name: 'neoFiIcon', key: neoFiIcon },
  { name: 'netellerIcon', key: netellerIcon },
  { name: 'onmeta1Icon', key: onmeta1Icon },
  { name: 'onrampIcon', key: onrampIcon },
  { name: 'paybisIcon', key: paybisIcon },
  { name: 'payPalIcon', key: payPalIcon },
  { name: 'paypayBankIcon', key: paypayBankIcon },
  { name: 'plaidIcon', key: plaidIcon },
  { name: 'rampIcon', key: rampIcon },
  { name: 'simplexIcon', key: simplexIcon },
  { name: 'skrillIcon', key: skrillIcon },
  { name: 'st1Icon', key: st1Icon },
  { name: 'stitchMoneyIcon', key: stitchMoneyIcon },
  { name: 'transakIcon', key: transakIcon },
  { name: 'treasureIcon', key: treasureIcon },
  { name: 'uIcon', key: uIcon },
  { name: 'UNLIMINTEULTDIcon', key: UNLIMINTEULTDIcon },
  { name: 'visaIcon', key: visaIcon },
  { name: 'wertIcon', key: wertIcon },
  { name: 'zenIcon', key: zenIcon },
]

export const colorsList = [
  { color: 'red', key: '#FF2A00' },
  { color: 'green', key: '#26AF61' },
  { color: 'blue', key: '#5A78EA' },
]

export const socialIconsList = [
  { name: 'Discord', key: discordIcon },
  { name: 'Mail', key: gmailIcon },
  { name: 'Facebook', key: facebookIcon },
  { name: 'Github', key: githubIcon },
  { name: 'Instagram', key: instagramIcon },
  { name: 'Linkedin', key: linkedinIcon },
  { name: 'Medium', key: mediumIcon },
  { name: 'Reddit', key: redditIcon },
  { name: 'Telegram', key: telegramIcon },
  { name: 'Teletype', key: teletypeIcon },
  { name: 'Threads', key: threadsIcon },
  { name: 'TikTok', key: tikTokIcon },
  { name: 'VK', key: VKlIcon },
  { name: 'Weibo', key: weiboIcon },
  { name: 'X', key: xIcon },
  { name: 'YouTube', key: youTubeIcon },
]
