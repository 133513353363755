<template>
  <vue-editor
    v-model="innerValue"
    :disabled="disabled"
    :editor-toolbar="customToolbar"
  />
</template>

<script>
import { VueEditor } from 'vue2-quill-editor';

export default {
  name: 'GlEditor',
  components: {
    VueEditor
  },
  props: {
    value: {
      type: [Object, Array, String],
      default: () => [],
    },
    customToolbar: {
      type: Array,
      default: () => [
        ["bold", "italic", "underline", "strike"],
        [{ align: [] }],
        ["link"],
      ],
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {}
  },
  computed: {
    innerValue: {
      get: function () {
        return this.value
      },
      set: function (e) {
        this.$emit('input', e)
      },
    },
  },

}
</script>
